<template>
	<div>
		<ScanLicense :options="scanOptions" :whoName="whoName" @close="closeScan" type="dl" />
		<ScanPassport :options="scanOptions" :whoName="whoName" @close="closeScan" />
		<a-modal :okButtonProps="{ disabled: true }" :visible="participantModal.show" :title="participantModal.title"
			class="modal-large" @ok="submitModal" @cancel="resetModal" :okText="participantModal.edit ? 'UPDATE' : 'ADD'"
			cancelText="CANCEL" centered>
			<a-form-model :model="purchaser" ref="purchaser" v-if="participantModal.type === 'purchaser'">
				<a-row :gutter="32">
					<a-col :span="12">
						<a-form-model-item label="First Name" prop="firstName" :rules="req(`First Name is required`)">
							<a-input type="text" v-model="purchaser.firstName" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="Last Name" prop="lastName" :rules="req(`Last Name is required`)">
							<a-input type="text" v-model="purchaser.lastName" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :span="12">
						<a-form-model-item label="Middle Name" prop="middleName">
							<a-input type="text" v-model="purchaser.middleName" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="Date of Birth" prop="dob" :rules="req(`Date of birth is required`)">
							<a-date-picker placeholder="eg. 1984-02-28" v-model="purchaser.dob"
								:disabled-date="disabledDate" style="width: 100%;" valueFormat="YYYY/MM/DD"></a-date-picker>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :span="12">
						<a-form-model-item label="Email" prop="email"
							:rules="{ validator: isEmail, required: true, message: 'Email is required' }" required>
							<a-input type="text" v-model="purchaser.email" :disabled="selectedContact != ''" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Phone#" prop="phone" :rules="req(`Phone is required`)">
							<a-input type="text" v-model="purchaser.phone" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :md="12">
						<a-form-model-item label="Business Phone#" prop="business">
							<a-input type="text" v-model="purchaser.business" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Cell Phone#" prop="cell">
							<a-input type="text" v-model="purchaser.cell" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :md="12">
						<a-form-model-item label="Company" prop="company">
							<a-input type="text" v-model="purchaser.company" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Occupation" prop="occupation">
							<a-input type="text" v-model="purchaser.occupation" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Job Title" prop="jobTitle">
							<a-input type="text" v-model="purchaser.jobTitle" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="SSN/SIN #" prop="ssnsin">
							<a-input type="text" v-model="purchaser.ssnsin" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Driver License #" prop="idValue"
							:rules="purchaser.passportOtherValue ? [] : req(`Driver License # is required`)">
							<a-input type="text" v-model="purchaser.idValue">
								<a-icon @click="addPurchaserModal('editScanLicense')" slot="suffix" type="camera" />
							</a-input>
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="License Issuing Jurisdiction" prop="issuing">
							<a-input type="text" v-model="purchaser.issuing" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="License Issuing Jurisdiction - Country" prop="issuingCountry">
							<a-input type="text" v-model="purchaser.issuingCountry" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="License Expiry" prop="expiry">
							<a-date-picker placeholder="eg. 1984-02-28" v-model="purchaser.expiry" style="width: 100%;"
								valueFormat="YYYY/MM/DD"></a-date-picker>
						</a-form-model-item>
					</a-col>
					<a-col :md="24">
						<a-tabs v-model="activeIdKey">
							<a-tab-pane v-for="(idType,idTypeI) in identificationTypes" :key="idType.value" :tab="idType.label">
								<a-col :md="12" v-if="idType.value == 'other'">
									<a-form-model-item label="Other ID Type Name" prop="otherName">
										<a-input type="text" v-model="purchaser.otherName" />
									</a-form-model-item>
								</a-col>
								<a-col :md="12">
									<a-form-model-item :label="passportOther.valueLabel[idType.value]" :prop="idType.value == 'pp' ? 'passportOtherValue' : idType.value + 'Value'"
									:rules="purchaser.idValue ? [] : req(`${passportOther.valueLabel[idType.value]} is required`)">
										<a-input type="text" v-model="purchaser[idType.value == 'pp' ? 'passportOtherValue' : idType.value + 'Value']">
											<a-icon v-if="idType.value === 'pp'" @click="addPurchaserModal('scanPassport')"
												slot="suffix" type="camera" />
										</a-input>
									</a-form-model-item>
								</a-col>
								<a-col :md="12">
									<a-form-model-item :label="passportOther.issuingLabel[idType.value]" :prop="idType.value == 'pp' ? 'passportOtherIssuing' : idType.value + 'Issuing'">
										<a-input type="text" v-model="purchaser[idType.value == 'pp' ? 'passportOtherIssuing' : idType.value + 'Issuing']"/>
									</a-form-model-item>
								</a-col>
								<a-col :md="12">
									<a-form-model-item
										:label="passportOther.issuingCountryLabel[idType.value]" :prop="idType.value == 'pp' ? 'passportOtherIssuingCountry' : idType.value + 'IssuingCountry'">
										<a-input type="text" v-model="purchaser[idType.value == 'pp' ? 'passportOtherIssuingCountry' : idType.value + 'IssuingCountry']" />
									</a-form-model-item>
								</a-col>
								<a-col :md="12" v-if="idType.value != 'bc'">
									<a-form-model-item :label="passportOther.expiryLabel[idType.value]" :prop="idType.value == 'pp' ? 'passportOtherExpiry' : idType.value + 'Expiry'">
										<a-date-picker placeholder="eg. 1984-02-28" v-model="purchaser[idType.value == 'pp' ? 'passportOtherExpiry' : idType.value + 'Expiry']"
											style="width: 100%;" valueFormat="YYYY/MM/DD"></a-date-picker>
									</a-form-model-item>
								</a-col>
								<template v-if="idType.value == 'bc'">
									<a-col :md="12">
										<a-form-model-item label="Birth Place" prop="birthPlace">
											<a-input type="text" v-model="purchaser.birthPlace" />
										</a-form-model-item>
									</a-col>
									<a-col :md="12">
										<a-form-model-item label="Birth Certificate Date of Registration" prop="registrationDate">
											<a-date-picker placeholder="eg. 1984-02-28" v-model="purchaser.registrationDate"
											style="width: 100%;" valueFormat="YYYY/MM/DD" :disabled-date="disabledDate"></a-date-picker>
										</a-form-model-item>
									</a-col>
									<a-col :md="12">
										<a-form-model-item label="Birth Certificate Date Issued" prop="issueDate">
											<a-date-picker placeholder="eg. 1984-02-28" v-model="purchaser.issueDate"
											style="width: 100%;" valueFormat="YYYY/MM/DD" :disabled-date="disabledDate"></a-date-picker>
										</a-form-model-item>
									</a-col>
								</template>
							</a-tab-pane>
						</a-tabs>
					</a-col>

					<a-col :span="24" class="mb-3">
						<hr />
					</a-col>

					<a-col :md="12">
						<a-form-model-item label="Address 1" prop="address1" :rules="req(`Address is required`)">
							<a-input type="text" v-model="purchaser.address1" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Address 2" prop="address2">
							<a-input type="text" v-model="purchaser.address2" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="City" prop="city" :rules="req(`City is required`)">
							<a-input type="text" v-model="purchaser.city" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="State/Province" prop="region"
							:rules="req(`State/Province is required`)">
							<a-input type="text" v-model="purchaser.region" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Zip/Postal Code" prop="postal" :rules="req(`Zip/Postal is required`)">
							<a-input type="text" v-model="purchaser.postal" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Country" prop="country" :rules="req(`Country is required`)">
							<a-input type="text" v-model="purchaser.country" />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
			<a-form-model :model="rep" ref="rep" v-else>
				<a-row :gutter="32">
					<a-col :span="12">
						<a-form-model-item label="First Name" prop="firstName" :rules="req(`First Name is required`)">
							<a-input type="text" v-model="rep.firstName" />
						</a-form-model-item>
					</a-col>
					<a-col :span="12">
						<a-form-model-item label="Last Name" prop="lastName" :rules="req(`Last Name is required`)">
							<a-input type="text" v-model="rep.lastName" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :span="12">
						<a-form-model-item label="Email" prop="email"
							:rules="{ validator: isEmail, required: true, message: 'Email is required' }">
							<a-input type="text" v-model="rep.email" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Phone#" prop="phone">
							<a-input type="text" v-model="rep.phone" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :md="12">
						<a-form-model-item label="Fax#" prop="phone">
							<a-input type="text" v-model="rep.fax" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Company Name" prop="company">
							<a-input type="text" v-model="rep.company" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :md="12">
						<a-form-model-item label="Job Title" prop="jobTitle">
							<a-input type="text" v-model="rep.jobTitle" />
						</a-form-model-item>
					</a-col>

					<a-col :md="12">
						<a-form-model-item label="Company Address 1" prop="address1" :rules="req(`Address is required`)">
							<a-input type="text" v-model="rep.address1" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :md="12">
						<a-form-model-item label="Company Address 2" prop="address2">
							<a-input type="text" v-model="rep.address2" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="City" prop="city" :rules="req(`City is required`)">
							<a-input type="text" v-model="rep.city" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :md="12">
						<a-form-model-item label="State/Province" prop="region" :rules="req(`State/Province is required`)">
							<a-input type="text" v-model="rep.region" />
						</a-form-model-item>
					</a-col>
					<a-col :md="12">
						<a-form-model-item label="Zip/Postal Code" prop="postal" :rules="req(`Zip/Postal is required`)">
							<a-input type="text" v-model="rep.postal" />
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="32">
					<a-col :md="12">
						<a-form-model-item label="Country" prop="country" :rules="req(`Country is required`)">
							<a-input type="text" v-model="rep.country" />
						</a-form-model-item>
					</a-col>

				</a-row>
			</a-form-model>
			<p class="text-danger" v-if="participantModal.errorMsg">{{ participantModal.errorMsg }}</p>
		</a-modal>

		<a-row :gutter="32">
			<a-col :span="12">
				<h4 class="mb-3" style="color: #40454c;">{{ whoName }}(s)</h4>
				<a-divider class="mb-0" />

				<!-- LIST OF ADDED PURCHASERS -->
				<ParticipantCard v-for="buyer in purchasersSummarized" :key="buyer.id" :item="buyer" @view="viewPurchaser"
					@edit="editPurchaser" @trash="(e) => editPurchaser(e, true)" />

				<!-- ADD NEW PURCHASER BUTTON -->
				<a-dropdown :trigger="['click']" style="width:auto" v-if="purchaserFields.length > purchasers.length"
					:disabled="!editingRights">
					<div class="dF py-4 aC w-full"
						:class="editingRights ? 'purchaser-side-add-card' : 'purchaser-side-add-card-disabled'">
						<div class="add-circle-dashed">
							<i class="fe fe-plus" />
						</div>
						<div class="f1 pl-4">
							Add {{ (purchaserFields[purchasers.length] && purchaserFields[purchasers.length].label) ||
								`${this.whoName} ${purchasers.length + 1}` }} <span
								v-if="purchaserFields[purchasers.length] && purchaserFields[purchasers.length].required"
								style="color: #f5222d">*</span>
						</div>
					</div>

					<a-menu slot="overlay" @click="addPurchaserModal">
						<a-menu-item key="scan">
							<i class="fe fe-camera" style="width:17px;display:inline-block" /> Scan Driver's License
						</a-menu-item>
						<a-menu-item key="scanPassport">
							<i class="fe fe-camera" style="width:17px;display:inline-block" /> Scan Passport
						</a-menu-item>
						<a-menu-item key="manual">
							<i class="fe fe-plus" style="width:17px;display:inline-block" /> Create Manually
						</a-menu-item>

					</a-menu>
				</a-dropdown>
				<p v-if="purchaserNotAdded" class="text-danger">Please add required {{ whoName }} data to continue</p>
			</a-col>

			<a-col :span="12" v-if="solicitorField">
				<h4 class="mb-3" style="color: #40454c;">{{ whoName }}'s Lawyer</h4>
				<a-divider class="mb-0" />

				<ParticipantCard v-if="repsSummarized.solicitor" :item="repsSummarized.solicitor" @view="viewRep"
					@edit="addEditRep" @trash="(e) => deleteRep(e, true)" />
				<div v-else class="dF py-4 aC w-full" @click="addEditRep('solicitor')"
					:class="editingRights ? 'purchaser-side-add-card' : 'purchaser-side-add-card-disabled'">
					<div class="add-circle-dashed">
						<i class="fe fe-plus" />
					</div>
					<div class="f1 pl-4">
						Add {{ solicitorField ? solicitorField.label : whoName + 's Lawyer' }} <span
							v-if="solicitorField?.required" style="color: #f5222d">*</span>
					</div>
				</div>
				<p v-if="solicitorNotAdded" class="text-danger">Please add required {{ whoName }}'s Lawyer data to continue
				</p>
			</a-col>
		</a-row>

		<a-modal v-model="viewParticipantData" :title="`${whoName}'s Details`" :footer="null" @close="resetData"
			:width="750" centered>
			<a-descriptions layout="horizontal" :column="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }">
				<a-descriptions-item label="First Name">
					{{ purchaser.firstName }}
				</a-descriptions-item>
				<a-descriptions-item label="Last Name">
					{{ purchaser.lastName }}
				</a-descriptions-item>
				<a-descriptions-item label="Middle Name">
					{{ purchaser.middleName }}
				</a-descriptions-item>
				<a-descriptions-item label="Email">
					{{ purchaser.email }}
				</a-descriptions-item>
				<a-descriptions-item label="Phone Number">
					{{ purchaser.phone }}
				</a-descriptions-item>
				<a-descriptions-item label="Date of Birth">
					{{ purchaser.dob }}
				</a-descriptions-item>
				<a-descriptions-item label="Business Phone#">
					{{ purchaser.business }}
				</a-descriptions-item>
				<a-descriptions-item label="Cell Phone#">
					{{ purchaser.cell }}
				</a-descriptions-item>
				<a-descriptions-item label="Company">
					{{ purchaser.company }}
				</a-descriptions-item>
				<a-descriptions-item label="Occupation">
					{{ purchaser.occupation }}
				</a-descriptions-item>
				<a-descriptions-item label="Job Title">
					{{ purchaser.jobTitle }}
				</a-descriptions-item>
				<a-descriptions-item label="SSN/SIN #">
					{{ purchaser.ssnsin }}
				</a-descriptions-item>
				<a-descriptions-item label="Driver License #">
					{{ purchaser.idValue }}
				</a-descriptions-item>
				<a-descriptions-item label="License Issuing Jurisdiction">
					{{ purchaser.issuing }}
				</a-descriptions-item>
				<a-descriptions-item label="License Expiry">
					{{ purchaser.expiry }}
				</a-descriptions-item>
				<a-descriptions-item label="Passport">
					{{ purchaser.passportOtherValue }}
				</a-descriptions-item>
				<a-descriptions-item label="Passport Issuing Jurisdiction">
					{{ purchaser.passportOtherIssuing }}
				</a-descriptions-item>
                <a-descriptions-item label="Passport Issuing Jurisdiction - Country">
					{{ purchaser.passportOtherIssuingCountry }}
				</a-descriptions-item>
				<a-descriptions-item label="Passport Expiry">
					{{ purchaser.passportOtherExpiry }}
				</a-descriptions-item>
                <template v-if="purchaser.bcValue">
					<a-descriptions-item label="Birth Certificate">
						{{ purchaser.bcValue }}
					</a-descriptions-item>
					<a-descriptions-item label="Birth Certificate Issuing Jurisdiction">
						{{ purchaser.bcIssuing }}
					</a-descriptions-item>
					<a-descriptions-item label="Birth Certificate Issuing Jurisdiction - Country">
						{{ purchaser.bcIssuingCountry }}
					</a-descriptions-item>
                    <a-descriptions-item label="Birth Place">
						{{ purchaser.birthPlace }}
					</a-descriptions-item>
                    <a-descriptions-item label="Birth Certificate Date of Registration">
						{{ purchaser.registrationDate }}
					</a-descriptions-item>
                    <a-descriptions-item label="Birth Certificate Date Issued">
						{{ purchaser.issueDate }}
					</a-descriptions-item>
                </template>
				<template v-if="purchaser.prValue">
					<a-descriptions-item label="PR">
						{{ purchaser.prValue }}
					</a-descriptions-item>
					<a-descriptions-item label="PR Issuing Jurisdiction">
						{{ purchaser.prIssuing }}
					</a-descriptions-item>
					<a-descriptions-item label="PR Issuing Jurisdiction - Country">
						{{ purchaser.prIssuingCountry }}
					</a-descriptions-item>
					<a-descriptions-item label="PR Expiry">
						{{ purchaser.prExpiry }}
					</a-descriptions-item>
                </template>
				<template v-if="purchaser.wpValue">
					<a-descriptions-item label="Work Permit">
						{{ purchaser.wpValue }}
					</a-descriptions-item>
					<a-descriptions-item label="Work Permit Issuing Jurisdiction">
						{{ purchaser.wpIssuing }}
					</a-descriptions-item>
					<a-descriptions-item label="Work Permit Issuing Jurisdiction - Country">
						{{ purchaser.wpIssuingCountry }}
					</a-descriptions-item>
					<a-descriptions-item label="Work Permit Expiry">
						{{ purchaser.wpExpiry }}
					</a-descriptions-item>
                </template>
				<template v-if="purchaser.otherValue">
					<a-descriptions-item :label="purchaser.otherName">
						{{ purchaser.otherValue }}
					</a-descriptions-item>
					<a-descriptions-item :label="`${purchaser.otherName} Issuing Jurisdiction`">
						{{ purchaser.otherIssuing }}
					</a-descriptions-item>
					<a-descriptions-item :label="`${purchaser.otherName} Issuing Jurisdiction - Country`">
						{{ purchaser.otherIssuingCountry }}
					</a-descriptions-item>
					<a-descriptions-item :label="`${purchaser.otherName} Expiry`">
						{{ purchaser.otherExpiry }}
					</a-descriptions-item>
                </template>
				<a-descriptions-item label="Address 1" :span="2">
					{{ purchaser.address1 }}
				</a-descriptions-item>
				<a-descriptions-item label="Address 2">
					{{ purchaser.address2 }}
				</a-descriptions-item>
				<a-descriptions-item label="City">
					{{ purchaser.city }}
				</a-descriptions-item>
				<a-descriptions-item label="State/Province">
					{{ purchaser.region }}
				</a-descriptions-item>
				<a-descriptions-item label="Zip/Postal Code">
					{{ purchaser.postal }}
				</a-descriptions-item>
				<a-descriptions-item label="Country">
					{{ purchaser.country }}
				</a-descriptions-item>
			</a-descriptions>

			<div v-if="purchaser.fileFront" class="text-center">
				<a-divider class="mt-3" style="background: black; margin: 5px 0px 10px"></a-divider>
				<h5>License Front Photo</h5>
				<img :src="purchaserFrontFileSignedUrl" alt="License Front Photo"
					style="max-width: 550px; max-height: 550px;" />
			</div>
			<div v-if="purchaser.fileBack" class="text-center">
				<a-divider class="mt-3" style="background: black; margin: 5px 0px 10px"></a-divider>
				<h5>License Back Photo</h5>
				<img :src="purchaserBackFileSignedUrl" alt="License Back Photo"
					style="max-width: 550px; max-height: 550px;" />
			</div>
			<div v-if="purchaser.filePassport" class="text-center">
				<a-divider class="mt-3" style="background: black; margin: 5px 0px 10px"></a-divider>
				<h5>Passport Photo</h5>
				<img :src="purchaserPassportFileSignedUrl" alt="Passport Photo"
					style="max-width: 550px; max-height: 550px;" />
			</div>
		</a-modal>

		<a-modal v-model="viewRepData"
			:title="`${rep.id === 'agent' ? `${whoName}\'s Agent` : `${whoName}\'s Lawyer`} Details`" :footer="null"
			@close="resetData" :width="750">
			<a-descriptions layout="horizontal" :column="{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }">
				<a-descriptions-item label="First Name">
					{{ rep.firstName }}
				</a-descriptions-item>
				<a-descriptions-item label="Last Name">
					{{ rep.lastName }}
				</a-descriptions-item>
				<a-descriptions-item label="Email">
					{{ rep.email }}
				</a-descriptions-item>
				<a-descriptions-item label="Phone Number">
					{{ rep.phone }}
				</a-descriptions-item>
				<a-descriptions-item label="Fax Number">
					{{ rep.fax }}
				</a-descriptions-item>
				<a-descriptions-item label="Company">
					{{ rep.company }}
				</a-descriptions-item>
				<a-descriptions-item label="Job Title">
					{{ rep.jobTitle }}
				</a-descriptions-item>
				<a-descriptions-item label="Address 1" :span="2">
					{{ rep.address1 }}
				</a-descriptions-item>
				<a-descriptions-item label="Address 2">
					{{ rep.address2 }}
				</a-descriptions-item>
				<a-descriptions-item label="City">
					{{ rep.city }}
				</a-descriptions-item>
				<a-descriptions-item label="State/Province">
					{{ rep.region }}
				</a-descriptions-item>
				<a-descriptions-item label="Zip/Postal Code">
					{{ rep.postal }}
				</a-descriptions-item>
				<a-descriptions-item label="Country">
					{{ rep.country }}
				</a-descriptions-item>
			</a-descriptions>
		</a-modal>
	</div>
</template>

<script>
import { validateEmail } from 'bh-mod'
import ParticipantCard from '@/components/common/ParticipantCard'
import moment from 'moment'
import ScanLicense from '@/components/common/scanLicense.vue'
import ScanPassport from '@/components/common/scanPassport.vue'

let isEmail = (rule, value, callback) => {
	if (value === '') {
		callback(new Error('Email is required'))
	} else if (!validateEmail(value)) {
		callback(new Error('Email is not valid'))
	}
	callback()
}

export default {
	components: { ParticipantCard, ScanLicense, ScanPassport },
	props: ['worksheetFields'],
	data: () => ({
		viewParticipantData: false,
		viewRepData: false,
		purchaserFrontFileSignedUrl: "",
		purchaserBackFileSignedUrl: "",
		purchaserPassportFileSignedUrl: "",
		scanOptions: {
			showLicenseScan: false,
			showPassportScan: false
		},
		activeIdKey:'pp',
		newTime: Date.now(),
		manualModal: false,
		selectedContact: '',
		showScanError: false,
		purchaserNotAdded: false,
		solicitorNotAdded: false,
		contactTags: [],
		purchasers: [],
		purchaserScan: {
			errorFront: false,
			errorBack: false,
			fileFront: null,
			fileBack: null,
			data: {},
		},
		reps: {
			agent: {
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				fax: '',
				company: '',
				jobTitle: '',
				address1: '',
				address2: '',
				country: '',
				region: '',
				city: '',
				id: '',
			},
			solicitor: {
				firstName: '',
				lastName: '',
				email: '',
				fax: '',
				phone: '',
				company: '',
				jobTitle: '',
				address1: '',
				address2: '',
				country: '',
				region: '',
				city: '',
				postal: '',
				id: '',
			}

		},
		isEmail,
		participantModal: {
			show: false,
			edit: false,
			type: 'purchaser',
			title: '',
			id: '',
			errorMsg: 'asdf',
		},
		rep: {
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			fax: '',
			company: '',
			jobTitle: '',
			address1: '',
			address2: '',
			postal: '',
			country: '',
			region: '',
			city: '',
		},
		purchaser: {
			id: '',
			firstName: '',
			middleName: '',
			lastName: '',
			email: '',
			phone: '',
			dob: '',
			business: '',
			cell: '',
			issuing: '',
			expiry: '',
			company: '',
			occupation: '',
			idType: 'pp',
			idValue: '',
			passportOtherValue: '',
			passportOtherIssuing: '',
			passportOtherIssuingCountry: '',
			passportOtherExpiry: '',
			prValue: '',
			prIssuing: '',
			prIssuingCountry: '',
			prExpiry: '',
			wpValue: '',
			wpIssuing: '',
			wpIssuingCountry: '',
			wpExpiry: '',
			bcValue: '',
			bcIssuing: '',
			bcIssuingCountry: '',
			otherName: '',
			otherValue: '',
			otherIssuing: '',
			otherIssuingCountry: '',
			otherExpiry: '',
            birthPlace: '',
            registrationDate: '',
            issueDate: '',
			address1: '',
			address2: '',
			postal: '',
			country: '',
			region: '',
			city: '',
			jobTitle: '',
			ssnsin: '',
		},
	}),

	computed: {
		purchaserLabel() {
			return ['Primary Purchaser', 'Secondary Purchaser']
		},
		whoName() {
			return 'Purchaser'
		},
		repsSummarized() {
			let obj = {
				agent: false,
				solicitor: false,
			}
			if (this.reps.solicitor.email) {
				let { firstName, lastName, id, email } = this.reps.solicitor
				obj.solicitor = { name: `${firstName} ${lastName}`, description: `${this.whoName}'s Lawyer (${email})`, id: id || 'solicitor' }
			}
			if (this.reps.agent.email) {
				let { firstName, lastName, id, email } = this.reps.agent
				obj.agent = { name: `${firstName} ${lastName}`, description: `${this.whoName}'s Agent (${email})`, id: id || 'agent' }
			}
			return obj
		},
		purchasersSummarized() {
			return this.purchasers.map((x, xI) => {
				return {
					name: `${x.firstName} ${x.lastName}`,
					description: (this.purchaserLabel[xI] || `${this.whoName} ${xI + 1}`) + ` (${x.email.toLowerCase()})`,
					id: x.id || x.email,
					invalid: !(x.idValue.length || x.passportOtherValue.length)
				}
			})
		},
		tags() {
			return this.$store.state.appData.tags
		},
		worksheetForm() {
			return this.$store.state.appData.selectedWorksheetForm;
		},
		purchaserFields() {
			if (this.worksheetForm) {
				return this.worksheetForm.fields.filter(f => !f.hide && f.type === 'purchaser')
			}
			return []
		},
		requiredPurchaserFields() {
			return this.purchaserFields.filter(p => p.required)
		},
		solicitorField() {
			if (this.worksheetForm) {
				return this.worksheetForm.fields.find(f => !f.hide && f.type === 'solicitor')
			}
			return false
		},
		user() {
			return this.$store.state.user.user
		},
		worksheet() {
			return this.$store.state.appData.selectedWorksheet || { status: 'pending' };
		},
		editingRights() {
			if (this.worksheet.status == 'pending' && !this.worksheet.id) return true;
			if (['approved', 'reserved', 'void', 'sold'].includes(this.worksheet.status)) return false

			return (this.worksheet.submittedBy?.id) === (this.user?.id)
		},

		currentContract() {
			let templates = this.worksheet.reservation.template && this.$store.state.appData.contractTemplates || [];
			return templates.find(t => t.id === this.worksheet.reservation.template) || {}
		},

		isMainEnvelopSigned() {
			if (!this.worksheet.reservation || !this.worksheet.reservation.envelops) {
				return false
			}
			let envelope = this.worksheet.reservation.envelops.filter(x => x.main)
			let isSigned = false;

			if (envelope?.length) {
				envelope = envelope[0];
				let signers = ''

				const recipientEvents = envelope.recipientEvents?.[envelope.recipientEvents.length - 1];
				if (!recipientEvents) {
					return
				}

				const recipients = recipientEvents?.data?.envelopeSummary?.recipients;
				signers = recipients?.signers || [];

				if (signers.length) {
					signers.forEach(s => {
						if (s.status === 'completed') {
							isSigned = true
						}
					})
				}
			}

			return isSigned;
		},

		identificationTypes() {
			return this.$store.state.appData.identificationTypes
		},

		passportOther() {
			return this.$store.state.appData.passportOther
		},
	},
	watch: {
		'purchaser.idType'() {
			this.$refs.purchaser?.clearValidate('passportOtherValue')
		},
		'purchaser.idValue'() {
			this.$refs.purchaser?.clearValidate('passportOtherValue')
		},
		'purchaser.passportOtherValue'() {
			this.$refs.purchaser?.clearValidate('idValue')
		},
	},
	methods: {
		closeScan() {
			this.scanOptions = {
				showLicenseScan: false,
				showPassportScan: false
			}
		},
		submitModal() {
			this.manualModal = false
			return this.createParticipant()
		},
		disabledDate(current) {
			return current && current > moment().startOf('day')
		},
		deleteRep(type) {
			let role = type === 'agent' ? 'Agent' : 'Lawyer'
			let obj = this.reps[type]
			let self = this

			this.$confirm({
				title: `Are you sure you want to delete ${self.whoName}'s ${role}:  ${obj.firstName} ${obj.lastName} ?`,
				okText: 'Delete',
				okType: 'danger',
				centered: true,
				onOk() {
					self.reps[type] = {
						firstName: '',
						lastName: '',
						email: '',
						phone: '',
						company: '',
						fax: '',
						business: '',
						cell: '',
						jobTitle: '',
						ssnsin: '',
						address1: '',
						address2: '',
						postal: '',
						country: '',
						region: '',
						city: '',
						id: '',
					}
				}
			})
		},
		addEditRep(type = 'solicitor') {
			if (!this.editingRights) return;
			let role = type === 'agent' ? 'Agent' : 'Lawyer'
			if (this.reps[type].id) {
				this.rep = JSON.parse(JSON.stringify(this.reps[type]))
				this.participantModal = {
					show: true,
					edit: true,
					title: `${this.whoName}'s ${role}`,
					type,
					id: '',
					errorMsg: '',
				}

			} else {
				this.participantModal = {
					show: true,
					edit: false,
					title: `${this.whoName}'s ${role}`,
					type,
					id: '',
					errorMsg: '',
				}
			}
		},
		editPurchaser(id = '', del = false) {
			if (!id) return null
			let idx = this.purchasers.findIndex(x => x.id === id || x.email === id)
			if (idx === -1) return null
			let self = this

			let purchaser = this.purchasers[idx]
			let pLabel = this.purchaserLabel[idx] || `${this.whoName} ${idx + 1}`

			if (del) {
				return this.$confirm({
					title: `Are you sure you want to delete ${self.whoName} #${idx + 1}:  ${purchaser.firstName} ${purchaser.lastName} ?`,
					okText: 'Delete',
					okType: 'danger',
					centered: true,
					onOk() {
						self.purchasers.splice(idx, 1)
					},
					onCancel() {
					},
				})

			}
			this.manualModal = true
			this.participantModal = {
				show: true,
				edit: true,
				title: 'Edit ' + pLabel,
				type: 'purchaser',
				id: id,
				errorMsg: '',
			}
			this.purchaser = { ...purchaser, idType: purchaser.idType === 'dl' ? 'pp' : purchaser.idType }
		},
		req: msg => ({ required: true, message: msg }),
		resetModal() {
			this.manualModal = false
			if (this.$refs.purchaser) {
				this.$refs.purchaser.resetFields()
				this.purchaser = {
					id: '',
					firstName: '',
					middleName: '',
					lastName: '',
					email: '',
					phone: '',
					dob: '',
					issuing: '',
					expiry: '',
					company: '',
					occupation: '',
					business: '',
					cell: '',
					idType: 'pp',
					idValue: '',
					passportOtherValue: '',
					passportOtherIssuing: '',
					passportOtherIssuingCountry: '',
					passportOtherExpiry: '',
					prValue: '',
					prIssuing: '',
					prIssuingCountry: '',
					prExpiry: '',
					wpValue: '',
					wpIssuing: '',
					wpIssuingCountry: '',
					wpExpiry: '',
					bcValue: '',
					bcIssuing: '',
					bcIssuingCountry: '',
					otherName: '',
					otherValue: '',
					otherIssuing: '',
					otherIssuingCountry: '',
					otherExpiry: '',
                    birthPlace: '',
                    registrationDate: '',
                    issueDate: '',
					address1: '',
					address2: '',
					postal: '',
					country: '',
					region: '',
					city: '',
					jobTitle: '',
					ssnsin: ''
				}
			}
			if (this.$refs.rep) {
				this.$refs.rep.resetFields()
				this.rep = {
					firstName: '',
					lastName: '',
					email: '',
					phone: '',
					fax: '',
					company: '',
					jobTitle: '',
					address1: '',
					address2: '',
					postal: '',
					country: '',
					region: '',
					city: '',
				}
			}

			this.participantModal = {
				show: false,
				edit: false,
				type: 'purchaser',
				title: '',
				id: '',
				errorMsg: '',
			}

			this.showScanError = false
			this.selectedContact = ''
			this.purchaserScan = {
				errorFront: false,
				errorBack: false,
				fileFront: null,
				fileBack: null,
				data: {}
			}
		},
		createParticipant() {
			let self = this
			if (this.participantModal.type === 'purchaser') {
				this.$refs.purchaser.validate(valid => {
					if (valid) {
						let newP = { ...self.purchaser }
						let firstName = newP.firstName
						let lastName = newP.lastName
						if (newP.middleName?.trim()) lastName = newP.middleName.trim() + ' ' + lastName

                        newP.fullName = `${firstName} ${lastName}`
						newP.address = `${newP.address1}${newP.address2 ? ', ' + newP.address2 : ''}`;
						newP.cityRegionPostalZip = `${newP.city}, ${newP.region}, ${newP.postal}`;
						newP.fullAddress = `${newP.address1}, ${newP.address2}, ${newP.city}, ${newP.region}, ${newP.postal}`.replaceAll(', , ', ', ').trim();

						if (newP.idValue != '') {
							newP.licenseIdType = "Driver's License"
						} else {
							newP.licenseIdType = ""
						}

                        if (newP.passportOtherValue) {
                            newP.passportOtherIdType = 'Passport';
                        } else {
                            newP.passportOtherIdType = '';
                        }

						if (newP.prValue) {
                            newP.prIdType = 'PR';
                        } else {
                            newP.prIdType = '';
                        }

						if (newP.wpValue) {
                            newP.wpIdType = 'Work Permit';
                        } else {
                            newP.wpIdType = '';
                        }

						if (newP.bcValue) {
                            newP.bcIdType = 'Birth Certificate';
                        } else {
                            newP.bcIdType = '';
                        }

						if (newP.otherValue) {
                            newP.otherName = newP.otherName;
                        } else {
                            newP.otherName = '';
                        }

						let id = Date.now();

						if (self.participantModal.edit) {
							let found = self.purchasers.find(p => p.email === newP.email && p.id !== newP.id);
							if (found) return self.participantModal.errorMsg = 'You already added this purchaser'
							found = self.purchasers.find(p => newP.idValue && (p.idValue === newP.idValue) && p.id !== newP.id)
							if (found) return self.participantModal.errorMsg = 'You already added this purchaser with same driver license number.'
							found = self.purchasers.find(p => newP.passportOtherValue && (p.passportOtherValue === newP.passportOtherValue) && p.id !== newP.id)
							if (found) return self.participantModal.errorMsg = 'You already added this purchaser with same passport number.'
							self.purchasers = self.purchasers.map(x => x.id === newP.id ? newP : x)
						} else {
							let found = self.purchasers.find(x => x.email === newP.email)
							if (found) return self.participantModal.errorMsg = 'You already added this purchaser with same email address.'
							found = self.purchasers.find(x => newP.idValue && (x.idValue === newP.idValue))
							if (found) return self.participantModal.errorMsg = 'You already added this purchaser with same driver license number.'
							found = self.purchasers.find(x => newP.passportOtherValue && (x.passportOtherValue === newP.passportOtherValue))
							if (found) return self.participantModal.errorMsg = 'You already added this purchaser with same passport number.'
							newP.id = id
							self.purchasers.push(newP)
						}

						self.participantModal.errorMsg = false

						if (self.purchasers.length >= self.requiredPurchaserFields.length) {
							self.purchaserNotAdded = false
						}

						if (this.worksheet.reservation?.envelops?.length) {
							let index = self.purchasers.findIndex(x => x.id === newP.id)
							self.updateParticipant(newP, index)
						} else if(self.participantModal.edit) {
							this.$emit('updateWorksheet')
						}

						self.resetModal()

					} else {
						return false;
					}
				})
			} else {
				this.$refs.rep.validate(valid => {
					if (valid && self.reps[self.participantModal.type]) {

						let newP = { ...self.rep }

						newP.fullName = `${newP.firstName} ${newP.lastName}`
						newP.id = self.participantModal.type
						newP.address = `${newP.company ? newP.company + ', ' : ''}${newP.address1}`;
						newP.cityRegionPostalZip = `${newP.city}, ${newP.region}, ${newP.postal}`;
						newP.fullAddress = `${newP.address1}, ${newP.address2}, ${newP.city}, ${newP.region}, ${newP.postal}`.replaceAll(', , ', ', ').trim();

						self.reps[self.participantModal.type] = JSON.parse(JSON.stringify(newP))

						self.participantModal.errorMsg = false
						self.solicitorNotAdded = false;

						if (this.worksheet.reservation?.envelops?.length) {
							self.updateParticipant(newP)
						} else if(self.participantModal.edit) {
							this.$emit('updateWorksheet')
						}

						self.resetModal()
					} else {
						return false;
					}
				})
			}
		},
		addPurchaserModal(e) {
			if (!this.editingRights) return;
			let pLabel = this.purchaserLabel[this.purchasers.length] || `${this.whoName} ${this.purchasers.length + 1}`
			if (e === 'editScanLicense') {
				this.scanOptions = {
					showLicenseScan: true,
					callback: purchaser => {

						this.purchaser = {
							...purchaser,
							amended: true,
							email: this.purchaser.email || '',
							phone: this.purchaser.phone || '',
						}

					},
				}
			} else if (e.key === 'scanPassport' || e === 'scanPassport') {
				this.scanOptions = {
					showPassportScan: true,
					callback: purchaser => {
						this.purchaser = {
							...this.purchaser,
							...purchaser,
							amended: true,
						}

						if (e.key === 'scanPassport') {
							this.participantModal = {
								show: true,
								edit: false,
								title: 'Add ' + pLabel,
								type: 'purchaser',
								id: '',
								errorMsg: '',
							}
						}
					},
				}
			} else if (e.key === 'manual') {
				this.participantModal = {
					show: true,
					edit: false,
					title: 'Add ' + pLabel,
					type: 'purchaser',
					id: '',
					errorMsg: '',
				}
				this.purchaser.idType = 'pp'
				this.purchaser.country = 'Canada'
				this.purchaser.region = 'Ontario'
			} else {
				this.scanOptions = {
					showLicenseScan: true,
					callback: purchaser => {
						this.purchaser = {
							...purchaser,
							amended: true,
						}

						this.participantModal = {
							show: true,
							edit: false,
							title: 'Add ' + pLabel,
							type: 'purchaser',
							id: '',
							errorMsg: '',
						}
					},
				}
			}
		},

		async updateParticipant(participant, index) {
			if (this.isMainEnvelopSigned) {
				return this.$message.error('The document is already signed by at least one person. You can not update the purchaser details.')
			}
			this.$store.commit('LOAD', true)
			// updating index to check relevant fields only from contract template
			let purchaserFieldIndex = index + 1;

			const signer = this.generateSingerObj(participant, purchaserFieldIndex)
			const type = this.participantModal.type === 'purchaser' ? 'purchasers' : 'reps'

			try {
				const payload = {
					...participant,
					signer
				}
				await this.$api.put(`worksheets/:instance/${this.worksheet.id}/${type}/${participant.id}`, payload)
				this.$store.commit('LOAD', false)
				this.$emit('updateWorksheet')
			} catch (err) {
				this.$store.commit('LOAD', false)
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while updating ${type} details. Please try again.`))
				}
			}
		},

		generateSingerObj(participant, purchaserIndex) {
			let contract = this.currentContract;
			let { pages = [] } = contract

			let signingObj = {
				solicitor: false,
				agent: false,
				signingParty: false,
				purchasers: false,
				purchaserSide: true,
				action: 'cc',
				actionDisabled: false,
				disabled: false,
				other: true,
				predefined: true,
				id: Date.now(),
				name: '',
				role: '',
				supportingText: '',
				email: '',
				note: '',
				choice: 'other',
			}

			const worksheetType = this.whoName;

			pages.forEach((page, pageI) => {
				let { fields = [] } = page
				fields.forEach(spot => {
					let { field, text } = spot
					let fV = field.value
					let fT = field.type
					let canSign = false

					if (fT === 'initial' || fT === 'sign') canSign = true
					if (signingObj.canSign) canSign = true

					if (fT === 'initial' || fT === 'sign' || fT === 'signedDate') {
						if (fV.includes('purchasers[]') && text.purchasers === purchaserIndex) {
							signingObj = {
								...signingObj,
								canSign,
								actionDisabled: true,
								disabled: true,
								order: 1,
								id: `${worksheetType}${text.purchasers},`,
								action: canSign ? 'sign' : 'cc',
								name: participant.fullName,
								role: `${worksheetType} #${text.purchasers}`,
								supportingText: `${worksheetType} #${text.purchasers}`,
								email: participant.email,
								purchasers: text.purchasers,
								signLocations: {}
							}

							signingObj.signLocations[spot.id] = {
								...spot,
								name: participant.fullName,
								email: participant.email,
								page: pageI,
								file: 0,
							}
						} else if (fV.includes('reps.agent') && participant.id === 'agent') {
							signingObj = {
								...signingObj,
								canSign,
								agent: true,
								actionDisabled: false,
								disabled: true,
								order: 1,
								id: 'agent',
								action: canSign ? 'sign' : 'cc',
								name: participant.fullName,
								role: `${worksheetType}'s Agent`,
								supportingText: `${worksheetType}'s Agent`,
								email: participant.email,
								signLocations: {}
							}

							signingObj.signLocations[spot.id] = {
								...spot,
								name: participant.fullName,
								email: participant.email,
								page: pageI,
								file: 0,
							}
						} else if (fV.includes('reps.solicitor') && participant.id === 'solicitor') {
							signingObj = {
								...signingObj,
								canSign,
								solicitor: true,
								actionDisabled: false,
								disabled: true,
								order: 1,
								id: 'solicitor',
								action: canSign ? 'sign' : 'cc',
								name: participant.fullName,
								role: `${worksheetType}'s Lawyer`,
								supportingText: `${worksheetType}'s Lawyer`,
								email: participant.email,
								signLocations: {},
							}

							signingObj.signLocations[spot.id] = {
								...spot,
								name: participant.fullName,
								email: participant.email,
								page: pageI,
								file: 0,
							}
						}
					}
				})
			})

			return signingObj;
		},

		async validate() {
			if (this.validateData()) {
				return false
			}
			this.$store.commit('LOAD', true);

			for (let index = 0; index < this.purchasers.length; index++) {
				const purchaser = this.purchasers[index];
				if (purchaser.fileFront && typeof purchaser.fileFront === 'object' && purchaser.fileBack && typeof purchaser.fileBack === 'object' && !purchaser.fileFront.url && !purchaser.fileBack.url) {
					let result = await Promise.all([this.uploadFile(purchaser.fileFront), this.uploadFile(purchaser.fileBack)]);
					this.purchasers[index].fileFront = result && result[0];
					this.purchasers[index].fileBack = result && result[1];
				}
				if (purchaser.filePassport && typeof purchaser.filePassport === 'object' && !purchaser.filePassport.url) {
					let result = await this.uploadFile(purchaser.filePassport);
					this.purchasers[index].filePassport = result;
				}
			}

			this.$store.commit('LOAD', false);

			this.purchaserNotAdded = false
			this.solicitorNotAdded = false
			let sendOBJ = {
				purchasers: this.purchasers,
				solicitor: this.reps?.solicitor || {},
				contactTags: this.contactTags
			}

			return sendOBJ
		},

		async uploadFile(file) {
			const formData = new FormData();
			formData.append('files', file, file.name);
			formData.append('force', 1);
			formData.append('isPrivate', 1);
			formData.append('path', `${this.whoName} Documents`);
			formData.append('instance', this.$store.state.instance.id);

			try {
				const { data } = await this.$api.post('/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
				return data && data[0];
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while trying to get draft worksheet details. Please try again.`))
				}
				return null
			}
		},

		validateData() {
			let error = false;
			if (!this.reps.solicitor?.email && this.solicitorField?.required) {
				this.solicitorNotAdded = true;
				error = true;
			} else {
				this.solicitorNotAdded = false;
			}

			if (this.purchasers.length < this.requiredPurchaserFields.length) {
				this.purchaserNotAdded = true;
				error = true;
			} else {
				this.purchaserNotAdded = false;
			}

			return error
		},

		viewPurchaser(id) {
			this.purchaser = this.purchasers.find(p => p.id === id || p.email === id)
			this.fetchPurchaserDocs({ ...this.purchaser })
			this.viewParticipantData = true;
		},

		viewRep(type) {
			this.rep = JSON.parse(JSON.stringify(this.reps[type]))
			this.viewRepData = true;
		},

		resetData() {
			this.viewParticipantData = false;
			this.viewRepData = false;

			this.rep = {
				amended: true,
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				fax: '',
				company: '',
				jobTitle: '',
				address1: '',
				address2: '',
				postal: '',
				country: '',
				region: '',
				city: '',
			}
			this.purchaser = {
				amended: true,
				id: '',
				firstName: '',
				middleName: '',
				lastName: '',
				email: '',
				phone: '',
				dob: '',
				cell: '',
				issuing: '',
				expiry: '',
				business: '',
				company: '',
				occupation: '',
				idType: 'pp',
				idValue: '',
				passportOtherValue: '',
				passportOtherIssuing: '',
				passportOtherIssuingCountry: '',
				passportOtherExpiry: '',
				prValue: '',
				prIssuing: '',
				prIssuingCountry: '',
				prExpiry: '',
				wpValue: '',
				wpIssuing: '',
				wpIssuingCountry: '',
				wpExpiry: '',
				bcValue: '',
				bcIssuing: '',
				bcIssuingCountry: '',
				otherName: '',
				otherValue: '',
				otherIssuing: '',
				otherIssuingCountry: '',
				otherExpiry: '',
                birthPlace: '',
                registrationDate: '',
                issueDate: '',
				address1: '',
				address2: '',
				postal: '',
				country: '',
				region: '',
				city: '',
				jobTitle: '',
				ssnsin: ''
			}
		},

		async fetchPurchaserDocs(purchaser) {
			this.$store.commit('LOAD', true);
			if (purchaser.fileFront?.id && purchaser.fileBack?.id) {
				let result = await Promise.all([this.fetchFileSignedUrl(purchaser.fileFront.id), this.fetchFileSignedUrl(purchaser.fileBack.id)]);
				if (result[0]) {
					this.purchaserFrontFileSignedUrl = result[0]
				}
				if (result[1]) {
					this.purchaserBackFileSignedUrl = result[1]
				}
			}
			if (purchaser.filePassport?.id) {
				const url = await this.fetchFileSignedUrl(purchaser.filePassport.id)
				if (url) {
					this.purchaserPassportFileSignedUrl = url
				}
			}
			this.$store.commit('LOAD', false);
		},

		async fetchFileSignedUrl(id) {
			try {
				const { data } = await this.$api.post(`upload/files/get-signed-url/${id}`)
				return data;
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching image url. Please try again.`))
				}
				return null
			}
		},
	},
	async created() {
		if (this.worksheetFields.purchasers?.length)
			this.purchasers = this.worksheetFields.purchasers.filter(p => p.email)
		if (this.worksheetFields.solicitor?.email) {
			this.reps.solicitor = { ...this.worksheetFields.solicitor }
		}
	},
	mounted() {
		this.$emit('setFn', this.validate)
	}
}
</script>

<style lang="scss">
.add-circle-dashed,
.participant-icon {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 1px dashed currentColor;
	display: flex;
	place-items: center;
	place-content: center;
	font-size: 20px;
	color: currentColor;
}

.participant-icon {
	border: 1px solid currentColor;
}

.purchaser-side-add-card {
	border-bottom: 1px solid var(--light-purple);
	color: var(--med-gray);
	cursor: pointer;

	&:hover {
		color: var(--primary);
	}
}

.purchaser-side-add-card-disabled {
	border-bottom: 1px solid var(--light-purple);
	color: var(--med-gray);
	cursor: not-allowed;
}
</style>
